.input {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.placeholder {
  flex-grow: 1;
  color: var(--color-font-grey);
  padding-right: var(--size-16);
}

.date-input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  font-size: 250%;
  border: none;
  opacity: 0;

  &::-webkit-calendar-picker-indicator {
    width: 100%;
    opacity: 0;
    padding: 0;
    margin: 0;
  }
}
