.report-card {
    background-color: var(--color-white);
    border-bottom: 1px solid var(--dashboard-card-border);
    box-sizing: border-box;
    padding: var(--size-8) var(--size-16) 0;
    font-size: var(--size-12);
    transition: transform .15s ease-out;
    font-family: var(--font-family-primary);
    line-height: var(--size-16);
    letter-spacing: var(--letter-spacing-regular);
    color: var(--dashbaord-secondary-text);
    font-weight: var(--font-weight-regular);

    .header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .sub-header {
            display: flex;
            align-items: center;
            line-height: var(--size-28);

            .card-title {
                color: var(--dashbaord-primary-text);
                font-size: var(--size-20);
                margin-right: var(--size-16);
            }

            .target-value {
                letter-spacing: var(--letter-spacing-medium);
            }
        }

        .view-trend {
            position: relative;
            left: var(--size-16);
            color: var(--see-trend);
            font-size: var(--size-14);
            line-height: var(--size-20);
            padding: var(--size-8) var(--size-16);
            font-weight: var(--font-weight-regular);
            transition: all 0.15s;
            cursor: pointer;
            text-align: center;
        }
    }

    .main-chart-container {
        display: flex;
        flex-direction: column;
        padding: var(--size-8) 0 0;

        .chart-container {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            height: 160px;
            margin-bottom: var(--size-16);
            transition: transform .15s ease-out;

            .main-data {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 50%;
                height: 100%;

                .chart-heading {
                    letter-spacing: var(--letter-spacing-medium);
                }
            }

            .secondary-data {
                min-width: 128px;
                width: 38%;

                @media only screen and (min-width: 360px) {
                    min-width: 136px;
                }
            }
        }

        .trend-chart-container {
            overflow: hidden;
            transition: all 0.5s;

            .trend-chart-wrapper-enter {
                height: 0;
            }

            .trend-chart-wrapper-enter-active {
                height: 345px;
                transition: height 0.5s;
            }
            
            .trend-chart-wrapper-enter-done {
                height: 345px;
                transition: height 0.5s;
            }
            
            .trend-chart-wrapper-exit {
                height: 345px;
            }

            .trend-chart-wrapper-exit-active {
                height: 0px;
                transition: height 0.5s;
            }

            .trend-chart-wrapper-exit-done {
                height: 0px;
            }

            .trend-chart-inner-container {
                border-top: 1px solid var(--dashboard-card-border);
                padding: var(--size-16) 0;

                .card-title {
                    color: var(--dashbaord-primary-text);
                    font-size: var(--size-16);
                    line-height: var(--size-32);
                }
            }
        }
    }
}