.title {
  width: 100%;
  padding: var(--size-18) var(--size-12);
  text-align: center;
  color: var(--color-font);
  font-size: var(--size-16);
  &.offline {
    padding-bottom: 0;
  }
}
.offlineTextInfo {
  padding: 10px;
  text-align: center;
}
