@import '~Style/mixins';

.container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  .icon {
    padding-right: var(--size-8);
    font-size: var(--size-20);
  }
  .title {
    @include text-overflow;
    flex: 1;
  }
  .more-info {
    text-decoration: underline;
  }
  &.expand {
    flex-direction: column;
    margin: var(--size-16) 0;
    .icon {
      font-size: var(--size-40);
      color: var(--color-font-grey);
    }
    .title {
      margin-top: var(--size-6);
      font-size: var(--size-16);
      font-weight: var(--font-weight-medium);
    }
    .message {
      font-size: var(--size-12);
      line-height: var(--size-15);
      &.first {
        margin-top: var(--size-8);
      }
      &.last {
        margin-bottom: var(--size-4);
      }
    }
    .close {
      color: var(--color-font-grey);
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}