.role {
  font-weight: var(--font-weight-regular);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--size-48);

  &.active {
    font-weight: var(--font-weight-medium);
  }
}