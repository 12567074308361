.issue-category {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .left {
    .gray-title {
      color: var(--color-font-grey);
      font-weight: var(--font-weight-medium);
      text-transform: uppercase;
    }
    .text {
      font-size: var(--size-14);
      margin-top: var(--size-5);
    }
  }
  .right {
    .link {
      font-size: var(--size-14);
      color: var(--color-primary);
      text-decoration: underline;
    }
  }
}