.error-message {
  margin: 10px 0px;
  display: flex;
  flex-direction: row;

  .icon {
    width: var(--size-30);
    color: orange;
    vertical-align: top;
    margin-top: var(--size-2);
  }
  .label {
    font-size: var(--size-12);
    line-height: 1.5;
    margin-left: var(--size-8);

    .bold-text {
      font-weight: var(--font-weight-medium);
    }
  }
}