@import '~Style/mixins';

.list-item {
  background-color: var(--color-white);
  flex-grow: 1;
  overflow: hidden;
  padding: var(--size-20) var(--size-20) 0 var(--size-20);

  &:last-child {
    padding: var(--size-20) var(--size-20) var(--size-20) var(--size-20);
  }

  .title {
    @include text-overflow;
    padding-bottom: var(--size-4);
    color: var(--color-font-grey);
    font-weight: var(--font-weight-medium);
    font-size: var(--size-12);
  }

  .value {
    @include text-overflow;
  }
}


