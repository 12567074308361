@import '~Style/mixins';

.list {
  @include text-overflow;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
