.order-time-entry {
  padding-bottom: var(--size-22);
  .time-and-name {
    font-size: var(--size-12);
    font-weight: var(--font-weight-medium);

    .time {
      padding-right: var(--size-12);
    }
  }

  .comment {
    font-size: var(--size-12);
    padding-top: var(--size-5);
  }

  .hours-cost {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: var(--color-background-off-white);
    margin-top: var(--size-12);
    padding: var(--size-8) var(--size-11) var(--size-8) 0;
    .cost {
      font-weight: var(--font-weight-medium);
    }
  }
}