.legend-wrapper {
    color: var(--dashbaord-primary-text);
    display: flex;

    .legends {
        display: flex;
        align-items: center;
        margin-right: var(--size-18);

        .legend-text {
            letter-spacing: var(--letter-spacing-medium);
        }

        .icon {
            margin-right: var(--size-8);
        }

        .current-unit {
            color: var(--color-blue-dasboard-data);
        }
    }
}