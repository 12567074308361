.header {
    width: 100%;
    padding: var(--size-14) var(--size-16);
    color: var(--color-black);
    font-size: var(--size-16);
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .date {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .offline {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .offline-switch {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            height: 24px;
        }

        .offline-text {
            padding-left: var(--size-8);
            font-size: var(--size-14);
            color: var(--color-font);
        }
    }
}