@import '~Style/mixins';

.list-item {
  display: flex;
  border-bottom: var(--border);
  background-color: var(--color-white);
  padding: var(--size-10) var(--size-16);
  align-items: center;
  justify-content: space-between;

  .title {
    flex-grow: 1;
    font-size: var(--size-16);
    line-height: 1.2;
    color: var(--color-black);
  }

  .fluid-value {
    color: var(--color-font-grey);
    white-space: nowrap;
    margin-left: var(--size-12);
  }

  .icon-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-font-grey);

    i {
      font-size: var(--size-24);
      border: var(--size-2) solid var(--color-font-grey);
      border-radius: 50%;
      padding: var(--size-3);
      margin-left: var(--size-12);

      &.cancel {
        border-color: var(--color-red-shine);
        color: var(--color-red-shine);

        &.checked {
          background-color: var(--color-red-shine);
          color: var(--color-white);
        }

        &.disabled {
          color: var(--color-font-light);
          border-color: var(--color-font-light);
        }
      }

      &.ok {
        border-color: var(--color-green-spring);
        color: var(--color-green-spring);

        &.checked {
          background-color: var(--color-green-spring);
          color: var(--color-white);
        }

        &.disabled {
          color: var(--color-font-light);
          border-color: var(--color-font-light);
        }
      }
    }
  }

  &.show-issues {
    margin-bottom: var(--size-8);
    box-shadow: var(--box-shadow);
  }
}

.register-issue-link {
  padding: var(--size-18) var(--size-16);
  color: var(--color-primary);
  text-decoration: underline;
  font-size: var(--size-13);
  border-bottom: var(--border);
}