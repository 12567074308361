@import '~Style/mixins';

.list-item {
  display: flex;
  border-bottom: var(--border);
  background-color: var(--color-white);
  .info {
    flex-grow: 1;
    padding: var(--size-18) 0 var(--size-18) var(--size-16);
    overflow: hidden;

    .title {
      @include text-overflow;
      font-weight: var(--font-weight-medium);
      font-size: var(--size-16);
      margin-bottom: var(--size-4);
    }

    .details {
      @include text-overflow;
      font-size: var(--size-12);
      color: var(--color-black);
      display: flex;
      justify-content: flex-start;
      align-items: center;

      span {
        margin-right: var(--size-6);
        opacity: 0.87;

        &.type {
          opacity: 1;
          padding: var(--size-3) var(--size-2);

          &.0 {
            background-color: var(--color-status-minor);
          }
          &.1 {
            background-color: var(--color-status-critical);
          }
          &.2 {
            background-color: var(--color-status-more-critical);
          }
          &.3 {
            background-color: var(--color-status-repair);
          }
        }

        &.status {
          @include text-overflow;
          margin-right: 0;
          &:before {
            content: "\2022";
            opacity: 0.87;
            padding-right: var(--size-6);
            color: var(--color-font);
          }
        }
      }
    }
  }

  .right-icon-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-font-grey);
    padding: var(--size-12) var(--size-16) var(--size-12) var(--size-8);
  }

  &.periodic-check-issues {
    background-color: transparent;
    margin-left: var(--size-16);

    .info {
      padding: var(--size-12) 0;
    }
  }
}


