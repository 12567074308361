.title-wrap {
  text-align: center;
  padding: 0 var(--size-12);
  overflow: hidden;

  .title,
  .sub-title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .title {
    font-size: var(--size-16);
    font-weight: var(--font-weight-medium);
    height: calc(var(--size-16) * var(--font-line-height));
    overflow: hidden;

    span {
      display: block;
    }
  }

  .title-enter-active,
  .title-exit-active {
    span {
      transition: all .15s ease-out;
    }
  }

  .title-enter {
    span {
      transform: translateY(0);
    }
  }

  .title-enter-active,
  .title-enter-done,
  .title-exit {
    span {
      transform: translateY(-50%);
    }
  }

  .title-exit-active {
    span {
      transform: translateY(0);
    }
  }

  .sub-title {
    max-height: var(--size-20);
    font-size: var(--size-12);
    font-weight: var(--font-weight-regular);
    color: var(--color-white-secondary);
    padding-top: var(--size-2);
  }

  .sub-title-enter-active,
  .sub-title-exit-active {
    transition: max-height .15s ease-out;
  }

  .sub-title-enter {
    max-height: 0;
  }

  .sub-title-enter-active,
  .sub-title-enter-done,
  .sub-title-exit {
    max-height: var(--size-16);
  }

  .sub-title-exit-active {
    max-height: 0;
  }

  .folder-icon {
    margin-right: var(--size-8);
    font-size: var(--size-22);
  }

  &.light {
    .sub-title {
      color: var(--color-black-secondary);
    }

    .folder-icon {
      color: var(--color-font-grey);
    }
  }
}
