.issue-info {
  background: var(--color-white);
  padding: var(--size-16);

  .issue-title {
    font-weight: var(--font-weight-medium);
    font-size: var(--size-16);
    padding-bottom: var(--size-8);
  }

  .issue-register-details {
    font-size: var(--size-12);
    color: var(--color-font-grey);
    padding-bottom: var(--size-24);
  }

  .info-wrapper {
    .title {
      color: var(--color-font-grey);
      text-transform: uppercase;
      font-weight: var(--font-weight-medium);
      font-size: var(--size-12);
      padding-bottom: var(--size-5);
    }

    .value {
      font-size: var(--size-14);
      display: flex;
      align-items: center;
      padding-bottom: var(--size-20);

      .status {
        font-size: var(--size-8);
        padding-right: var(--size-8);

        &.0 {
          color: var(--color-status-register);
        }
        &.1 {
          color: var(--color-status-inprogress);
        }
        &.2 {
          color: var(--color-status-completed);
        }
        &.3 {
          color: var(--color-status-closed);
        }
      }
    }
  }

  .bottom-line {
    margin: 0px;
  }
}