.model-body {
  padding: var(--size-16);

  .form-label {
    line-height: 1.5;
    text-transform: uppercase;
    font-size: var(--size-12);
    font-weight: var(--font-weight-medium);
  }

  .quantity {
    display: flex;
    flex-direction: row;
    flex: auto;
    margin-top: var(--size-8);
    border: var(--border);
    border-radius: var(--border-radius);
    overflow: hidden;
    background-color: var(--color-background-off-white);

    .value{
      flex: auto;
      font-size: var(--size-70);
      flex-direction: column;
      display: flex;
      justify-content: center;
      align-items: center;

      .quantity-type {
        font-size: var(--size-16);
        color: var(--color-font-grey);
        text-transform: uppercase;
      }
    }

    .buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-size: var(--size-70);
      background-color: var(--color-black-secondary);
      color: var(--color-white);

      i {
        padding: var(--size-12);
        font-size: var(--size-70);
      }
    }
  }
}