.footer {
  margin-top: 16px;
  width: 100%;
  display: flex;
  flex-direction: row;

  .abort {
    margin-right: var(--size-12);
    padding: var(--size-12) 0;
    width: 35%;
    font-weight: var(--font-weight-medium);
  }
  .register {
    width: 62%;
    padding: var(--size-12) 0;
    font-weight: var(--font-weight-medium);
    background-color: var(--color-primary);
    color: var(--color-white);
    border-color: var(--color-primary);
    opacity: 1;

    &:disabled {
      opacity: 0.4;
    }
  }
}