@import '~Style/mixins';

.list-item {
  display: flex;
  border-bottom: var(--border);
  background-color: var(--color-white);

  .info {
    flex-grow: 1;
    padding: var(--size-17) var(--size-20) var(--size-18);
    overflow: hidden;
  }

  .name,
  .code {
    @include text-overflow;
  }

  .name {
    font-size: var(--size-16);
    margin-bottom: var(--size-4);
    color: var(--color-black);
  }

  .code {
    font-size: var(--size-12);
  }

  .right-icon-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-font-grey);
    padding: var(--size-12) var(--size-16) var(--size-12) var(--size-8);
  }
}