@import '~Style/mixins';

.msg-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 var(--size-16);
  min-height: var(--size-40);
  flex-shrink: 0;

  &.dark {
    background-color: var(--color-black);
    color: var(--flash-yellow);
  }
  &.info, &.3 {
    background-color: var(--flash-blue);
    color: var(--color-white);
  }
  &.warning, &.0 {
    background-color: var(--flash-yellow);
  }
  &.error, &.1 {
    background-color: var(--flash-red);
    color: var(--color-white)
  }
  &.critical, &.2 {
    background-color: var(--flash-red-dark);
    color: var(--color-white)
  }

  &.success {
    background-color: var(--flash-green);
    color: var(--color-white)
  }

  &.dueSoon {
    background-color: var(--flash-yellow-gold);
  }

  .icon {
    padding-right: var(--size-8);
    font-size: var(--size-20);
  }

  .content {
    @include text-overflow;
    flex: 1;
    .info {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .text {
        @include text-overflow;
        flex: 1;
      }

      .link {
        text-decoration: underline;
      }
    }
  }

}