.instruction {
  padding: var(--size-16);
  color: var(--color-font);
  background-color: var(--color-white);

  h1 {
    font-size: var(--size-20);
    font-weight: var(--font-weight-medium);
  }

  h2 {
    font-size: var(--size-18);
    font-weight: var(--font-weight-medium);
  }

  h3 {
    font-size: var(--size-16);
    font-weight: var(--font-weight-medium);
  }

  p {
    font-size: var(--size-14);

    &.light {
      color: var(--color-font-grey);
      margin-top: var(--size-32);
    }
  }
}