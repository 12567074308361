.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-shrink: 0;
  height: var(--size-64);
  font-size: var(--size-12);
  color: var(--color-font-light-gray);
  align-self:flex-end;
  width: 100%;
}