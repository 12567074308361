.menu-item {
  padding: var(--size-14);
  background-color: var(--color-white);
  height: var(--size-60);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: var(--color-font);
  font-weight: var(--font-weight-medium);
  border-bottom: 1px solid var(--color-body);

  i {
    font-size: var(--size-22);
    color: var(--color-font-grey);

    &.open {
      transform: rotateY(180deg);
    }
  }

  &:hover, &:active, &:focus {
    text-decoration: none;
    color: var(--color-font);
    background-color: var(--color-offwhite);
  }

  &.font-color-red {
    color: var(--color-red-secondary);

    i {
      color: var(--color-red-secondary);
    }
  }

  &.child-menu {
    padding-left: var(--size-34);
  }
}