.last-week-average-data {
    .title {
        color: var(--dashbaord-secondary-text);
        letter-spacing: var(--letter-spacing-medium);
    }
    .data {
        font-size: var(--size-26);
        color: var(--dashbaord-primary-text);
        line-height: var(--size-40);
        @media only screen and (min-width: 360px) {
            font-size: var(--size-28);
        }
    }
}