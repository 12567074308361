@import '~Style/mixins';

.list-item {
    display: flex;
    border-bottom: var(--border);
    background-color: var(--color-white);

    .main-wrapper {
        display: flex;
        width: 100%;
        padding: var(--size-16) var(--size-16) var(--size-16) var(--size-20);

        &.unread {
            background-color: var(--message-unread-status);
        }

        .info {
            overflow: hidden;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: flex-start;
            width: 100%;

            .title {
                @include text-overflow;
                font-weight: var(--font-weight-medium);
                font-size: var(--size-16);
                color: var(--color-font);
                width: 100%;

                span {
                    padding-right: var(--size-6);
                }
            }

            .issue-description {
                @include text-overflow;
                color: var(--color-font);
                font-size: var(--size-14);
                padding: var(--size-4) 0 0;
                width: 100%;
            }

            .message-sent-details {
                font-size: var(--size-14);
                padding: var(--size-4) 0 0;
                color: var(--color-font-grey);
                width: 100%;
                @include text-overflow;

                .message {
                    padding-left: var(--size-4);
                }
            }
        }
        .user-avatar-wrapper {
            height: var(--size-40);
            position: relative;
            &.unread::before {
                content: '';
                width: var(--size-8);
                height: var(--size-8);
                top: 0;
                bottom: 0;
                right: var(--size-56);
                border-radius: 50%;
                margin: auto;
                position: absolute;
                background: var(--color-primary);
            }
            .user-avatar {
                color: var(--color-font);
                background-color: var(--color-border);
                margin-right: var(--size-12);
                width: var(--size-40);
                height: var(--size-40);
            }
        }

        .message-sent-date {
            font-size: var(--size-12);
            line-height: var(--size-18);
            display: flex;
            flex: 0 0 auto;
            justify-content: flex-end;
            align-items: flex-start;
            color: var(--color-font-grey);
        }
    }
}