.menu {
  margin: var(--size-8) 0 0 0;
  padding: 0;
  text-align: center;
  background-color: var(--color-white);
  border-radius: var(--border-radius);

  li {
    display: block;
    padding: var(--size-12);
    cursor: pointer;
    border-bottom: 1px solid var(--color-border);
    font-weight: var(--font-weight-medium);

    &:last-child {
      border-bottom: 1px solid transparent;
    }
  }

  .logout {
    color: var(--color-red);
  }

  .abort {
    color: var(--color-primary);
  }
}
