.header {
  display: flex;
  flex-direction: row;
  font-size: var(--size-14);
  color: var(--color-font-grey);
  z-index: 1;
  position: sticky;
  left: 0;
  top: 0;
  background-color: var(--color-body);
  .link {
    padding: var(--size-19) 0 var(--size-18) 0;
    text-align: center;
    flex: 1;
    &.active {
      border-bottom: var(--size-3) solid;
      color: var(--color-black);
      font-weight: var(--font-weight-medium);
    }
  }

}