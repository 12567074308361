.app-header {
  position: relative;
  z-index: 5;
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  height: var(--size-56);
  color: #fff;
  background-color: var(--color-header);
  padding: 0 var(--size-16);

  &.light {
    background-color: var(--color-white);
    color: var(--color-black);
    border-bottom: var(--border);
  }
}

.left-wrap {
  min-width: var(--size-32);
}

.right-wrap {
  display: flex;
  align-items: center;

  .chat-icon-wrap {
    position: relative;
    padding-right: var(--size-16);

    .notification-badge-icon::after {
      content: '';
      position: absolute;
      width: var(--size-8);
      height: var(--size-8);
      border-radius: 50%;
      bottom: var(--size-18);
      right: var(--size-16);
      background-color: var(--color-status-register);
      animation: circleScale 2s infinite;
    }
  }
}
.logo {
  width: 85px;
}
@keyframes circleScale {
  0% {
    transform: scale(1)
  }

  50% {
    transform: scale(1.2)
  }

  100% {
    transform: scale(1)
  }
}