.footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0 var(--size-16) var(--size-16) var(--size-16);
  .abort {
    margin-right: var(--size-12);
    padding: var(--size-12) 0;
    width: 35%;
    font-weight: var(--font-weight-medium);
    font-size: var(--size-16);
  }
  .register {
    width: 62%;
    padding: var(--size-12) 0;
    font-weight: var(--font-weight-medium);
    background-color: var(--color-primary);
    color: var(--color-white);
    border-color: var(--color-primary);
    font-size: var(--size-15);
  }
}