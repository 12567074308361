.menu {
  margin: 0;
  padding: 0;
  text-align: center;
  color: var(--color-primary);

  li {
    display: block;
    padding: var(--size-12);
    cursor: pointer;
    border-bottom: 1px solid var(--color-border);
    font-weight: var(--font-weight-medium);
  }
}
