.add-issue {
  top: 10%;
  left: var(--size-16);
  right: var(--size-16);
  bottom: var(--size-16);
  margin: 0 auto;
  max-width: 360px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}