@import '~Style/mixins';

.list-item {
  display: flex;
  border-bottom: var(--border);
  background-color: var(--color-white);

  .info {
    flex: 1;
    padding: var(--size-17) var(--size-16) var(--size-18) 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .name,
  .description {
    width: 100%;
    @include text-overflow;
  }

  .name {
    font-size: var(--size-16);
    color: var(--color-black);
  }

  .description {
    font-size: var(--size-12);
    margin-top: var(--size-4);
  }

  .right-icon-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-font-grey);
    padding: var(--size-24) var(--size-16) var(--size-24) 0;
  }
}