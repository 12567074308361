@import '~Style/mixins';

.issue-message {
  display: flex;
  justify-content: center;
  height: var(--size-40);
  padding: 0 var(--size-16);
  align-items: center;

  &.0 {
    background-color: var(--color-background-off-white);
  }

  &.1 {
    background-color: var(--color-status-inprogress-background);
  }

  &.2 {
    background-color: var(--color-status-finished-background);
  }

  &.3 {
    background-color: var(--color-status-closed-background);
  }

  .icon {
    padding-right: var(--size-8);
    font-size: var(--size-20);
  }

  span {
    @include text-overflow;
    flex-grow: 1;
  }
}