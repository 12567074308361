.dimmer {
  .path {
    stroke: #fff;
  }
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .percentage {
    width: 100%;
    position: absolute;
    text-align: center;
    display: inline-block;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    color: var(--color-font-title);
  }

  $sizes: 12, 14, 16, 20, 24, 32, 60;
  @each $size in $sizes {
    &.size-#{$size} {
      width: var(--size-#{$size});
      height: var(--size-#{$size});

      .percentage {
        font-size: var(--size-#{$size/2});
      }
    }
  }

  &.inline {
    position: relative;
    top: 0;
    left: 0;
    transform: none;

    &.center {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &.inverse {
    .path {
      stroke: #fff;
    }
  }
}

.spinner {
  width: 100%;
  height: 100%;

  &.spin {
    animation: rotator 1.4s linear infinite;
  }

  @keyframes rotator {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(270deg);
    }
  }
}

.path {
  stroke: var(--color-primary);
  stroke-width: 6;
  stroke-linecap: round;
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  fill: none;
  transform-origin: center;

  &.percentage-random {
    animation: dash 1.4s ease-in-out infinite;
  }

  @keyframes dash {
    0% {
      stroke-dashoffset: 187;
    }
    50% {
      stroke-dashoffset: 46.75;
      transform: rotate(135deg);
    }
    100% {
      stroke-dashoffset: 187;
      transform: rotate(450deg);
    }
  }

  @for $i from 0 through 100 {
    &.percentage-#{$i} {
      stroke-dashoffset: #{187 - 187 * $i /100};
    }
  }
}


