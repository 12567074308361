@import '~Style/mixins';

.list-item {
  display: flex;
  border-bottom: 1px solid var(--color-border);
  background-color: #fff;
}

.info {
  flex-grow: 1;
  padding: var(--size-12) var(--size-16);
  overflow: hidden;
}

.name,
.code {
  @include text-overflow;
}

.name {
  font-weight: var(--font-weight-medium);
  margin-bottom: var(--size-4);
}

.code {
  color: var(--color-font-grey);
}

.favorite-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-yellow);
  padding: var(--size-12) var(--size-16);

  &.in-favorite {
    color: var(--color-yellow);
  }
}

.category-image {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 12px;

  img {
    height: var(--size-24);
    width: var(--size-56);
  }
}