@import '~Style/common';

#app,
.main-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}
