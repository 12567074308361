.languages {
  display: flex !important;
  border-bottom: 1px solid var(--color-border);
  padding: 0 !important;

  .language {
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding: var(--size-12);
    cursor: pointer;

    &.active {
      cursor: not-allowed;
      background-color: var(--color-border);
    }

    &:hover:not(.active) {
      background: var(--color-hover);
    }

    .flag {
      margin-right: var(--size-10);
    }

    &:first-child {
      border-top-left-radius: var(--border-radius);
    }

    &:last-child {
      border-top-right-radius: var(--border-radius);
    }
  }
}
