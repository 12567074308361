.slider {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 280px;
  overflow: hidden;
  position: relative;

  .image {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .buttons {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;

    .text {
      display: flex;
      flex-grow: 7;
      justify-content: center;
      align-items: flex-end;

      .slider-text {
        background-color: var(--color-dimmer);
        color: var(--color-white);
        padding: var(--size-8);
        font-weight: lighter;
        font-size: var(--size-12);
      }
    }

    .icon-div {
      display: flex;
      justify-content: center;
      flex-grow: 1;
      align-items: center;

      &.right {
        transform: rotate(180deg);
      }

      .icon {
        font-size: var(--size-24);
        padding: var(--size-12) var(--size-8) var(--size-12) var(--size-16);
        color: white;
        background-color: var(--color-dimmer);
        border-radius: 50%;
      }
    }
  }
}