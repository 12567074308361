.header {
  width: 100%;
  padding: var(--size-20) var(--size-16);
  font-weight: var(--font-weight-medium);
  border-bottom: var(--border);

  .title {
    font-size: var(--size-20);
    margin-bottom: var(--size-16);
  }

  .last-registered {
    font-size: var(--size-12);
    color: var(--color-font-grey);
    margin-bottom: var(--size-5);
  }

  .user {
    font-size: var(--size-14);
    margin-bottom: var(--size-3);
  }

  .date {
    font-weight: var(--font-weight-regular);
    font-size: var(--size-12);
  }
}
