.main-sidebar {
  position: absolute;
  z-index: var(--z-index-main-menu);
  top: 0;
  left: 0;
  width: 100%;
  max-width: var(--main-menu-width);
  height: 100%;
  border-right: 1px solid var(--color-body);
  background-color: var(--color-body);
  overflow: hidden;
  transform: translateX(-100%);
  transition: transform .15s ease-out;

  &.open {
    transform: translateX(0);
  }

  .menu-title {
    font-size: var(--size-11);
    font-weight: var(--font-weight-medium);
    color: var(--color-font-title);
    padding: var(--size-25) var(--size-14) var(--size-10) var(--size-14);
    display: flex;
    justify-content: space-between;
  }
}

.overlay {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: var(--color-font-grey);
  opacity: 0;
  transition: z-index 0s .3s, opacity .3s ease-out;

  &.show {
    z-index: var(--z-index-main-menu-overlay);
    opacity: 1;
    transition-delay: 0s;
  }
}
