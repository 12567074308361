@import '~react-toastify/scss/main';

.Toastify__toast {
  min-height: var(--size-48);
  font-family: inherit;
  border-radius: 0;
  box-shadow: none;
  padding: 0 var(--size-12);
  border-radius: var(--size-4);
  margin: var(--size-24) var(--size-12);
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: var(--size-16);

  &--info {
    background: var(--color-primary);
  }
  &--success {
    background: var(--color-black);
    &:before {
      font-family: "Material Icons";
      content: "check";
      font-size: var(--size-24);
      margin-right: var(--size-12);
      white-space: nowrap;
    }
  }
  &--warning {
    background: var(--color-yellow);
  }
  &--error {
    background: var(--color-red-secondary);
    &:before {
      font-family: "Material Icons";
      content: "report_problem";
      font-size: var(--size-20);
      margin-right: var(--size-12);
      white-space: nowrap;
    }
  }
  &--default {
    background: var(--color-primary);
  }
}
