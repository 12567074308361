.footer {
  width: 100%;
  position: fixed;
  display: flex;
  align-content: space-between;
  bottom: 0;
  flex-direction: row;
  border-top: var(--border);
  padding: var(--size-16);
  background-color: var(--color-body);
  font-size: var(--size-16);
  justify-content: space-between;

  .abort {
    width: calc(35% - 6px);
    padding: var(--size-12) 0;
    font-weight: var(--font-weight-medium);
  }

  .submit {
    width: calc(65% - 6px);
    padding: var(--size-12) 0;
    color: var(--color-white);
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    font-weight: var(--font-weight-medium);
    opacity: 1;

    &:disabled {
      opacity: 0.4;
    }
  }
}