.form {

}

.field {
  position: relative;
  margin-bottom: var(--size-22);

  &:last-child {
    margin-bottom: 0;
  }

  &.has-errors {
    .input {
      border-color: var(--color-red);
    }
  }
}

.label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: var(--size-16);
  line-height: 1.3125;
  font-weight: var(--font-weight-medium);
  margin-bottom: var(--size-8);
}

.input-wrap {
  position: relative;

  &.icon-left {
    .icon-wrap {
      left: 0;
    }
    .input {
      padding: var(--size-12) var(--size-16) var(--size-12) var(--size-44);
    }
  }
  &.icon-right {
    .icon-wrap {
      right: 0;
    }
    .input {
      padding: var(--size-12) var(--size-44) var(--size-12) var(--size-16);
    }
  }
}

.input {
  display: block;
  width: 100%;
  font-family: inherit;
  font-size: var(--size-16);
  line-height: 1.3125;
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius);
  background: #fff;
  box-shadow: none;
  padding: var(--size-12) var(--size-16);
  transition: all .15s ease-out;

  @at-root textarea#{&}{
    resize: vertical;
    min-height: 120px;
    &.auto-size {
      min-height: 0px;
      resize: none;
    }
  }

  &:focus {
    outline: none;

    &:not(:read-only) {
      border-color: var(--color-primary);
    }
  }
}

.select {
  appearance: none;

  &::-ms-expand {
    display: none;
  }
}

.icon-wrap {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--size-48);
  height: 100%;
  color: var(--color-font-grey);

  &:not(.icon-element) {
    pointer-events: none;
  }
}

.description {
  font-size: var(--size-12);
  margin-top: var(--size-6);

  &.before-input {
    margin-bottom: var(--size-7);
  }
}

.errors {
  position: absolute;
  top: 100%;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  font-size: var(--size-12);
  color: var(--color-red);
  margin-top: var(--size-6);

  span {
    font-size: 175%;
    line-height: .7;
    margin: 0 3px;
  }
}
