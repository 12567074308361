.input {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.placeholder {
  flex-grow: 1;
  padding-right: var(--size-16);
}

.checkbox-input {
  display: none;

  &:checked {
    ~ .icon {
      color: var(--color-primary);
    }
  }
}

.icon {
  color: var(--color-font-grey);
  transition: color .15s ease-out;
}
