.input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--size-10) var(--size-16);
}

.placeholder {
  flex-grow: 1;
  color: var(--color-font-grey);
  padding-right: var(--size-16);
}

.counter-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
}

.button {
  padding: var(--size-6);

  > span {
    height: auto;
  }
}

.counter {
  text-align: center;
  font-size: var(--size-36);
  line-height: 1;
  border: none !important;
  border-radius: 0;
  background: transparent;
  padding: 0;
}
