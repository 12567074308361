.search-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 0 var(--size-12);
  transition: all .15s ease-out;
}

.toggle {
  color: var(--color-font-grey);
}
