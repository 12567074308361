.work-info {
  background: var(--color-white);
  padding: var(--size-4) var(--size-16) var(--size-16) var(--size-16);

  .order-number {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .title {
      font-weight: var(--font-weight-medium);
    }
    padding-bottom: var(--size-22);
  }

  .bottom-line {
    margin: 0px;
  }
}