@import '~Style/mixins';

.sub-title {
  @include text-overflow;
}

.sub-title {
  margin-top: var(--size-1);
  color: var(--color-font);
  font-size: var(--size-12);
  padding: var(--size-3) 0 0;
  &.ok {
    background-color: var(--color-green-secondary);
    padding: var(--size-3) var(--size-2);

    .green-icon {
      color: var(--color-green-spring);
      padding-left: var(--size-5);
    }
  }
}