.missing-data-container {
    padding: 0 var(--size-16) var(--size-10);

    .missing-data-banner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: var(--error-message-background);
        border-left: var(--size-3) solid var(--error-message-text);
        color: var(--error-message-text);
        padding: var(--size-10) var(--size-16);

        .message {
            line-height: var(--size-20);
            font-size: var(--size-12);
            font-weight: var(--font-weight-medium);
        }
        
        .icon {
            font-size: var(--size-14);
        }

        @media only screen and (min-width: 360px) {
            .icon {
                font-size: var(--size-16);
            }
            .message {
                font-size: var(--size-14);
            }
        }
    }
}