.list {

}

.title {
  font-size: var(--size-12);
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
  color: var(--color-font-grey);
  border-bottom: 1px solid var(--color-border);
  padding: var(--size-24) var(--size-14) var(--size-12) var(--size-16);
}
