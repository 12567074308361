@import '~Style/mixins';

.input {
  display: none;
}

.file-input-container {
  @include text-overflow;
  padding: var(--size-12);
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--color-background-off-white);
  font-size: var(--size-14);

  i {
    color: var(--color-primary);
  }

  .title {
    @include text-overflow;
    flex: auto;
    color: var(--color-primary);
    padding-left: var(--size-12);
  }
  .description {
    color: var(--color-font-grey);
  }
}