.message {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: var(--size-16);

  &:last-child {
    padding-bottom: 0;
  }

  .time {
    font-size: var(--size-12);
    color: var(--color-font-grey);
    padding-right: var(--size-16);
  }

  .info {
    flex: 1;
    .name {
      font-size: var(--size-12);
      color: var(--color-light-blue);
      padding-bottom: var(--size-4);
      opacity: 0.87;
    }
    .text {
      white-space: pre-line;
    }
  }

  .action {
    width: 100%;
    text-align: center;
    color: var(--color-black);
    font-size: var(--size-12);
    font-weight: var(--font-weight-medium);
  }
  .message-date {
    width: 100%;
    text-align: center;
    border-bottom: var(--border);
    line-height: 0.1em;
    font-size: var(--size-12);
    color: var(--color-font-grey);
    margin: var(--size-6) 0 var(--size-20);

    span {
      background: var(--color-messages-body);
      padding: 0 10px;
    }
  }
}