.message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: var(--color-font-grey);
}

.icon {
  color: var(--color-font-light);
  margin-bottom: var(--size-16);
}

.title {
  font-size: var(--size-24);
  margin-bottom: var(--size-16);
  text-align: center;
}
