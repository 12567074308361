.user {
  display: flex;
  flex-direction: row;
  font-weight: var(--font-weight-medium);

  .user-avatar {
    color: var(--color-font);
    background-color: var(--color-border);
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: var(--size-12);

    .email {
      color: var(--color-font-grey);
      font-size: var(--size-12);
      font-weight: var(--font-weight-regular);
      margin-top: var(--size-2);
    }
  }
}