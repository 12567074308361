.wrap {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.icon {
  color: var(--color-font-light);
  margin-bottom: 16px;
}

.title {
  font-size: 24px;
  font-weight: var(--font-weight-medium);
  color: var(--color-font-grey);
  margin-bottom: 16px;
}
