.field {
  flex-shrink: 0;
  padding: 0 var(--size-12);
  margin-bottom: 0;
}

.input {
  &:focus {
    border-color: var(--color-border) !important;
  }
}
