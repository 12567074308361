$content-padding: var(--size-32);

.button {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  font-size: var(--size-16);
  font-weight: var(--font-weight-medium-strong);
  color: var(--color-button-border);
  border: 2px solid var(--color-button-border);
  border-radius: var(--border-radius);
  background: #fff;
  box-shadow: none;
  padding: var(--size-14) var(--size-16);

  &:hover,
  &:focus {
    text-decoration: none;
    outline: none;
  }

  &.block {
    display: block;
    width: 100%;

    &.has-icon {
      .content {
        padding: 0 $content-padding;
      }
    }
  }

  &.disabled {
    opacity: .5;
  }

  &.link {
    font-weight: var(--font-weight-regular);
    color: var(--color-primary);
    border: none;
    background-color: transparent;
    padding: 0;

    .content {
      text-decoration: underline;
    }
  }

  &.primary {
    color: #fff;
    border-color: var(--color-primary);
    background-color: var(--color-primary);
  }
  &.red {
    color: #fff;
    border-color: var(--color-red);
    background-color: var(--color-red);
  }
  &.green {
    color: #fff;
    border-color: var(--color-green);
    background-color: var(--color-green);
  }
  &.yellow {
    border-color: var(--color-yellow);
    background-color: var(--color-yellow);
  }

  &.loading {
    color: transparent;
  }

  &.has-icon {
    &.has-icon-left {
      .content {
        padding-left: $content-padding;
      }
    }
    &.has-icon-right {
      .content {
        padding-right: $content-padding;
      }
    }
  }
}

.content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--size-24);
}

.icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  &:first-child {
    left: 0;
  }
  &:last-child {
    right: 0;
  }
}
