$openCircleOffset: calc(var(--size-4) * -1);

.user-avatar {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--size-32);
  height: var(--size-32);
  line-height: 1.1;
  border-radius: 50%;
  color: #fff;
  background-color: var(--color-primary);
  user-select: none;

  &.user-name-intials::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, .1);
    transition: all .15s ease-out;
  }

  &.active,
  &.hovering:hover {
    &::before {
      top: $openCircleOffset;
      left: $openCircleOffset;
      right: $openCircleOffset;
      bottom: $openCircleOffset;
    }
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    color: var(--color-black);
    background-color: var(--color-alto);
  }
}