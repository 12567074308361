.additional-info {
    font-size: var(--size-12);
    line-height: var(--size-16);
    .icon {
        font-size: var(--size-14);
        padding-right: var(--size-8);
    }
    &.above-average {
        color: var(--color-low-performance);
    }
    &.below-average {
        color: var(--color-high-performance);
    }
    &.on-average, &.grey {
        color: var(--dashbaord-secondary-text);
    }
    @media only screen and (min-width: 360px) {
        font-size: var(--size-14);
        .icon {
            font-size: var(--size-16);
        }
    }
}