@import '~Style/mixins';

.model-body {
  padding: var(--size-16);

  .form-label {
    line-height: 1.5;
    text-transform: uppercase;
    font-size: var(--size-12);
    font-weight: var(--font-weight-medium);

    &.valid {
      color: var(--color-green-spring);
    }

    &.invalid {
      color: var(--color-red);
    }

    &.warning {
      color: var(--color-yellow);
    }
  }

  .input-box {
    position: relative;
    margin-bottom: var(--size-15);

    label {
      color: var(--color-font-grey);
      position: absolute;
      height: 80%;
      right: var(--size-2);
      top: var(--size-6);
      padding: var(--size-5) var(--size-10);
      font-size: var(--size-20);
      z-index: 10;
      font-weight: var(--font-weight-regular);
      background-color: var(--color-white);
    }

    input {
      font-size: var(--size-36);
      line-height: var(--size-36);
      margin-top: var(--size-5);
      height: var(--size-64);
      padding: var(--size-12);
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      @include placeholder {
        font-size: var(--size-36);
        transform: scale(0.55);
        transform-origin: 0% 50%;
        color: var(--color-font-light-gray);
        opacity: 1;
        line-height: normal !important;
      }

      &.valid {
        border: var(--size-2) solid var(--color-green-spring);
      }

      &.invalid {
        border: var(--size-2) solid var(--color-red);
      }

      &.warning {
        border: var(--size-2) solid var(--color-yellow);
      }
    }

  }
}