@import '~Style/mixins';

.list-item {
  @include text-overflow;
  display: block;
  width: calc(50% - 5px);
  padding: var(--size-14) var(--size-12);
  border: 1px solid var(--color-border);
  background-color: var(--color-background-off-white);
  border-radius: var(--size-4);
  margin-top: var(--size-12);

  &.active {
    background-color: var(--color-green-secondary);
    border: 1px solid var(--color-green-spring);
  }
}