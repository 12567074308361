.model-content {
  padding: var(--size-16);
  line-height: var(--size-20);

  .message {
    padding: 0 var(--size-4);
    &.bold-text {
      font-weight: var(--font-weight-medium);
    }
  }

  .checkbox-wrapper {
    display: flex;
    justify-content: center;
    margin-top: var(--size-16);
    div {
      width: 100%;

      .checkbox {
        background-color: var(--color-background-off-white);
        padding: var(--size-12) var(--size-14);
        font-size: var(--size-14);
        flex-direction: row-reverse;
        border: 0;
        border-radius: var(--size-4);

        i {
          font-size: var(--size-24);
          margin-right: var(--size-12);
        }

        &.checked {
          background-color: var(--color-green-secondary);
          i {
            color: var(--color-green-spring)
          }
        }
      }
    }
  }
}
