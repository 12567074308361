.details {
  .input-container {
    margin-top: var(--size-16);
    margin-bottom: 0;
    &.file {
      label {
        margin-bottom: var(--size-4);
      }
    }
    label {
      text-transform: uppercase;
      font-size: var(--size-12);
    }

    textarea {
      font-size: var(--size-14);
      min-height: 100px;
    }

    select {
      font-size: var(--size-14);
      font-weight: var(--font-weight-regular);
    }

    i {
      font-size: var(--size-24);
      padding-left: var(--size-4);
    }
  }
  
  .issue-register-details {
    font-size: var(--size-12);
    color: var(--color-font-grey);
    padding-bottom: var(--size-16);
  }
}