.model-header {
  border-bottom: var(--border);
  padding: var(--size-18);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: var(--color-black);
  .icon {
    padding-right: var(--size-16);
  }
  .header-content {
    .title {
      font-weight: var(--font-weight-medium);
      font-size: var(--size-16);
    }
  }
}