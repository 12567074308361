//@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
$material-design-icons-font-directory-path: '~material-design-icons-iconfont/dist/fonts/';
@import '~material-design-icons-iconfont/src/material-design-icons';

.icon {
  display: inline-block;
  vertical-align: middle;
  font-family: 'Material Icons';
  font-size: var(--size-24);
  line-height: 1;
  font-weight: normal;
  font-style: normal;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';

  &.flip-horizontal {
    transform: rotateY(180deg);
  }
  &.flip-vertical {
    transform: rotateX(180deg);
  }

  &.big {
    font-size: var(--size-60);
  }
  &.small {
    font-size: var(--size-16);
  }

  @for $i from 1 through 60 {
    &.size-#{$i} {
      font-size: var(--size-#{$i});
    }
  }
}
