.list-item {
  border: var(--border);
  background-color: #fff;
  padding: var(--size-14) var(--size-16);

  &:first-child {
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
  }
  &:last-child {
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
  }
  &:not(:last-child) {
    border-bottom: none;
  }
}
