@import '~Style/mixins';

.list-item {
  display: flex;
  border-bottom: 1px solid var(--color-border);
  background-color: #fff;

  &.bottom-space{
    margin-bottom: 12px;
  }

  &.top-space{
    margin-top: 12px;
  }
}

.info {
  flex-grow: 1;
  padding: var(--size-12) var(--size-16) var(--size-12) 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.title {
  @include text-overflow;
}

.title {
  font-size: var(--size-16);
  color: var(--color-black);
}

.right-icon-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-font-grey);
  padding: var(--size-24) var(--size-16);
}

.left-icon-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-font);
  padding: var(--size-24) var(--size-18);
}