.modal {
  position: absolute;
  right: var(--size-12);
  //bottom: var(--size-12);
  left: var(--size-12);
  border-radius: var(--border-radius);
  background-color: #fff;
  overflow: hidden;
  transition: all .15s ease-out;

  &.bottom {
    bottom: var(--size-12);
  }

  &.top {
    top: var(--size-12);
  }

  &.center {
    top: 20%;
    vertical-align: middle;
  }

  &.top-center {
    top: 10%;
    vertical-align: middle;
  }

  &.content {
    right: var(--size-20);
    left: var(--size-20);
    margin: 0 auto;
    max-width: 360px;
  }

  &.enter {
    transform: translateY(150%);
  }
  &.enter-active {
    transform: translateY(0);
  }
  &.exit {
    transform: translateY(0);
  }
  &.exit-active {
    transform: translateY(150%);
  }
}

