.file-preview-container {
  display: flex;
  flex-direction: row;
  padding: var(--size-12) 0;
  overflow-x: auto;
  .preview {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    img {
      height: 99px;
      max-width: fit-content;
    }

    .icon {
      position: relative;
      color: var(--color-red-monza);
      font-size: var(--size-20);
      top: -11px;
      left: -14px;
    }
  }
}