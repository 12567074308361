.content {
  margin: 0 auto;
  right: var(--size-8);
  left: var(--size-8);
  top: var(--size-8);
  bottom: var(--size-8);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  .content-window {
    width: 100%;
    height: 100%;
  }
  .abort {
    display: flex;
    justify-content: flex-end;
  }
}
