@mixin heading-tags {
  @for $i from 1 through 6 {
    h#{$i} {
      @content;
    }
  }
}

@mixin center-box($position: absolute) {
  position: $position;
  top: 50%;
  left: 50%;

  transform: translate3d(-50%, -50%, 0);
}

@mixin flex-col($width: 100%) {
  flex-basis: $width;
  max-width: $width;
}

@mixin overlay($position: absolute, $z-index: 3) {
  position: $position;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $z-index;
}

@mixin clear-fix {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin radial-box($radial) {
  &::before {
    content: '';
    position: relative;
    display: block;
    width: 100%;
    padding-top: $radial;
    z-index: -1;
  }
}

@mixin transition($property: all, $duration: .15s, $t-func: linear) {
  transition: $duration $t-func;
  transition-property: $property;
}

@mixin text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}
}