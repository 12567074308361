.form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: var(--size-8);
  .user-avatar {
    margin-right: var(--size-12);
    color: var(--color-black);
    background-color: var(--color-alto);
  }
  .field {
    flex: 1;
  }
  .input {
    font-size: var(--size-14);
  }
}

.buttons {
  width: 100%;
  display: flex;
  align-content: space-between;
  bottom: 0;
  flex-direction: row;
  font-size: var(--size-16);
  justify-content: space-between;
  padding: var(--size-4) 0 var(--size-8) 0;
  .abort {
    width: calc(35% - 6px);
    padding: var(--size-12) 0;
    font-weight: var(--font-weight-medium);
  }

  .submit {
    width: calc(65% - 6px);
    padding: var(--size-12) 0;
    color: var(--color-white);
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    font-weight: var(--font-weight-medium);
  }
}