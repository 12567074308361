html,
body,
#root {
  height: 100%;
  overflow: hidden;
}

html {
  font: var(--font-weight-regular) var(--font-size)/var(--font-line-height) var(--font-family-primary);
}

body {
  color: var(--color-font);
  background: var(--color-body);

  &.overflow-hidden {
    overflow-y: hidden
  }
}

img {
  max-width: 100%;
  margin: 0 auto;
}

label {
  cursor: pointer;
}

sup,
sub {
  font-size: 50%;
}

strong {
  font-weight: var(--font-weight-medium);
}

hr {
  margin: var(--size-22) 0;
  border: none;
  border-bottom: var(--border);
}

a,
.link {
  text-decoration: none;
  color: var(--color-primary);
  cursor: pointer;

  &:hover,
  &:focus {
    text-decoration: underline;
    color: var(--color-primary);
  }

  &.custom-link,
  &.link-block {
    color: inherit;

    &:hover,
    &:focus {
      color: inherit;
    }
  }

  &.link-block {
    display: block;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}
