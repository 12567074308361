.categories {
  font-weight: var(--font-weight-medium);

  .title {
    text-transform: uppercase;
  }

  .sub-title {
    font-weight: var(--font-weight-regular);
    margin-top: var(--size-5);
  }
}