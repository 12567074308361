.closed-issue-info {
  background: var(--color-white);
  padding: var(--size-4) var(--size-16) var(--size-16) var(--size-16);

  .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: var(--font-weight-medium);
    padding-bottom: var(--size-22);
  }

  .info {
    padding-bottom: var(--size-22);
    .time-and-name {
      font-size: var(--size-12);
      font-weight: var(--font-weight-medium);

      .time {
        padding-right: var(--size-12);
      }
    }

    .comment {
      font-size: var(--size-12);
      padding-top: var(--size-5);
    }
  }

  .bottom-line {
    margin: 0;
  }
}