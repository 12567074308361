@import '~Style/mixins';

.statutes {
  @include text-overflow;
  display: flex;
  margin-top: var(--size-1);
  .status {
    margin-right: var(--size-2);
    font-size: 12px;
    color: var(--color-black);
    padding: var(--size-2) var(--size-2);

    &.0 {
      background-color: var(--color-status-minor);
    }
    &.1 {
      background-color: var(--color-status-critical);
    }
    &.2 {
      background-color: var(--color-status-more-critical);
    }
  }
}