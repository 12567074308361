.light-box {
  position: absolute;
  overflow: hidden;
  transition: all .15s ease-out;
  width: 100%;
  height: 100%;
  background: transparent;
  padding: 32px 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .close {
    position: absolute;
    top: var(--size-8);
    right: var(--size-8);
    color: var(--color-white);
    background-color: var(--color-dimmer);
  }

  .image {
    overflow: hidden;
    object-fit: contain;
  }

  &.enter {
    transform: translateY(150%);
  }
  &.enter-active {
    transform: translateY(0);
  }
  &.exit {
    transform: translateY(0);
  }
  &.exit-active {
    transform: translateY(150%);
  }
}

